<template>
  <component :is="layout">
    <div class="row fadeInUp content-body" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-header">
                <h5>Edit Display template</h5>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div
                    class="form-group col-12 required"
                    :class="{ 'is-invalid': errors.has('name') }"
                  >
                    <label for="name" class="control-label text-capitalize"
                      >Display template name</label
                    >
                    <input
                      id="name"
                      v-model="form.data.name"
                      v-validate="{ required: true }"
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Display template name"
                      autocomplete="off"
                    />
                    <span v-show="errors.has('name')" class="invalid-feedback">{{
                      errors.first("name")
                    }}</span>
                  </div>
                </div>
                <ul class="nav mb-3 border-bottom">
                  <li class="nav-item border">
                    <a
                      class="nav-link text-uppercase"
                      :class="activeTab === 'code' ? 'active bg-light' : ''"
                      aria-current="page"
                      @click.prevent="activeTab = 'code'"
                      >Code</a
                    >
                  </li>
                  <li class="nav-item border">
                    <a
                      class="nav-link text-uppercase"
                      :class="activeTab === 'data' ? 'active bg-light' : ''"
                      @click.prevent="activeTab = 'data'"
                      >Test data</a
                    >
                  </li>
                </ul>
                <div v-if="activeTab === 'code'" class="row">
                  <div class="col-sm-6">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Editor
                        </h3>
                      </div>
                      <div class="panel-body">
                        <textarea id="editor" v-model="code" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          HTML Output
                        </h3>
                      </div>
                      <div class="panel-body">
                        <div class="outputPanel" v-html="renderedTemplate" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <p class="text-muted">
                      Read about constructing a display here:
                      <a target="_blank" href="https://automation.app/documentation/twig"
                        >Twig documentation</a
                      >
                    </p>
                  </div>
                </div>
                <div v-if="activeTab === 'data'" class="row">
                  <div class="col-sm-12">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          Latest order json data
                          <button
                            class="btn btn-primary btn-sm float-right"
                            @click="loadLatestOrder"
                          >
                            Load latest order
                          </button>
                        </h3>
                      </div>
                      <div class="panel-body">
                        <textarea id="testdata" v-model="testData" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <ButtonLoading
                  :loading="loading"
                  :title="'Save display template'"
                  :class="'btn btn-outline-primary'"
                  v-on:click.native="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import { postToApi, updateApi, loadData } from "@/helpers/apiConnection";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "DisplayTemplate",
  components: {
    Default,
    ConfigurationNav,
    ButtonLoading,
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("/configurationData/" + _id).then((response) => {
      next((vm) => {
        vm.id = _id;
        vm.form = response.data;
        if (vm.form.data.code) {
          vm.code = vm.form.data.code;
          vm.testData = vm.form.data.testData;
          vm.compiledData();
        }
      });
    });
  },
  data() {
    return {
      layout: "Default",
      id: "",
      code: "",
      testData: "{}",
      activeTab: "code",
      template: "",
      form: {
        data: { name: "" },
        group: "display",
      },
      loading: false,
    };
  },
  computed: {
    renderedTemplate() {
      return this.template;
    },
  },
  watch: {
    code: function() {
      this.compiledData();
    },
  },
  methods: {
    async compiledData() {
      try {
        const testData = this.testData ?? "{}";
        const data = JSON.stringify(JSON.parse(testData.trim()));
        this.template = await postToApi("organization/mail/render", {
          data: data,
          template: this.code,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    async save() {
      this.loading = true;
      const display = this.form;
      display.data.code = this.code;
      display.data.testData = this.testData;
      await updateApi(
        "/configurationData/" + this.id,
        display,
        "Display saved"
      );
      this.loading = false;
    },
    async loadLatestOrder() {
      const orders = await loadData(
        "/order",
        "1",
        "orders.created_at",
        "desc",
        "per_page=1"
      );
      if (orders.data.length) {
        const order = await loadData(`/order/${orders.data[0].id}`);
        this.testData = JSON.stringify(order, null, 4);
      }
    },
  },
};
</script>

<style scoped>
#editor,
#testdata {
  /** Setting height is also important, otherwise editor wont showup**/
  width: 100%;
  height: 400px;
}

.outputPanel {
  height: 400px;
  overflow-y: auto;
  border: 1px solid lightgrey;
  margin-bottom: 5px;
}
</style>
